import React from "react";
import "./App.scss";
import NavBar from "./Components/NavBar/NavBar";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Skills from "./Components/Skills/Skills";
// import Projects from "./Components/Projects/Projects";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import ProjectsGallery from "./Components/ProjectsGallery/ProjectsGallery"; // Import the ProjectsGallery component

import AceBakeryImg from "./assets/Images/acebakery.png";
import Atkinson from "./assets/Images/atkinson.png";
import valorHockey from "./assets/Images/valorhockey.png";
import stonefire from "./assets/Images/stonefire.png";
import ChoosingWisely from "./assets/Images/choosingwisely.png";
import DrugChecking from "./assets/Images/drugchecking.png";
import GardenCulture from "./assets/Images/gardenculture.png";
import Foodee from "./assets/Images/foodee.png";

function App() {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  // Add an event listener to update the screen width state when the window is resized
  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const projectData = [
    {
      id: 1,
      img: AceBakeryImg,
      title: "Ace Bakery",
      info: "Played a key role in developing and maintaining the Ace Bakery and Ace Bakery foodservice websites. Recent work included designing and deploying the 'Who we serve' pages. Utilized HTML, CSS, jQuery, JavaScript, and PHP to enhance site functionality and user experience. Focused on responsive design and optimized performance for a diverse range of user devices.",
      cms: "WordPress",
      techStack: ["HTML", "CSS", "JavaScript", "PHP", "jQuery", "Flickity JS"],
      link: "https://foodservice.acebakery.com/foodservice-restaurants/",
    },
    {
      id: 2,
      img: valorHockey,
      title: "Valor Hockey",
      info: "Led the development of an e-commerce site for a new line of hockey helmets using Shopify. Customized the site using HTML, CSS, jQuery, and Shopify's Liquid templating language. Focused on intuitive navigation and user experience, integrating product catalogs with secure payment gateways for streamlined online shopping.",
      cms: "Shopify",
      techStack: ["HTML", "CSS", "JavaScript", "Liquid", "jQuery"],
      link: "https://valorhockey.com/en-ca",
    },
    {
      id: 3,
      img: Atkinson,
      title: "Atkinson Foundation",
      info: "Developed a responsive, searchable grants database for the Atkinson Foundation. Implemented Isotope JS for dynamic filtering and enhanced the site with vanilla JavaScript for additional functionality. Ensured cross-browser compatibility and optimized loading times for a seamless user experience.",
      cms: "WordPress",
      techStack: ["HTML", "CSS", "JavaScript", "Isotope JS"],
      link: "https://atkinsonfoundation.ca/grants-database/",
    },
    {
      id: 4,
      img: stonefire,
      title: "Stonefire",
      info: "Supported the overhaul and redesign of Stonefire Consumer and Foodservice websites. Personally developed and implemented language and location switchers, as well as new 'Who we serve' pages. Focused on enhancing site accessibility and user navigation, ensuring a consistent experience across various regions.",
      cms: "WordPress",
      techStack: ["HTML", "CSS", "JavaScript", "PHP", "Flickity JS"],
      link: "https://foodservice.stonefire.com/restaurants/",
    },
    {
      id: 5,
      img: ChoosingWisely,
      title: "Choosing Wisely Canada",
      info: "Enhanced the Choosing Wisely Canada website, a key resource for the Canadian medical community. Developed new search functionalities to assist doctors in efficiently finding relevant resources. Focused on ensuring high reliability and speed for improved user access to critical information.",
      cms: "WordPress",
      techStack: ["HTML", "CSS", "JavaScript", "PHP"],
      link: "https://choosingwiselycanada.org/",
    },
    {
      id: 6,
      img: DrugChecking,
      title: "Toronto's Drug Checking Service",
      info: "Contributed to the development of Toronto's drug checking community website. Assisted in content overhaul and rebranding efforts, focusing on clear communication and user-friendly navigation to provide vital public health information effectively.",
      cms: "WordPress",
      techStack: ["HTML", "CSS", "JavaScript", "PHP"],
      link: "https://drugchecking.community/",
    },
    {
      id: 7,
      img: GardenCulture,
      title: "Garden Culture Magazine",
      info: "Developed a custom video feature for Garden Culture Magazine, enabling the integration of YouTube and Vimeo players at the top of articles. Implemented a custom video player interface, enhancing the user engagement and multimedia experience on the site.",
      cms: "WordPress",
      techStack: ["HTML", "CSS", "JavaScript", "PHP", "jQuery"],
      link: "https://gardenculturemagazine.com/",
    },
    {
      id: 8,
      img: Foodee,
      title: "Foodee",
      info: "Provided continuous maintenance and content management for Foodee's website. Focused on ensuring seamless functionality and up-to-date content for the food delivery app, enhancing user experience and platform reliability.",
      cms: "WordPress",
      techStack: ["HTML", "CSS", "JavaScript", "PHP"],
      link: "https://www.food.ee/",
    },
  ];

  return (
    <div className="App">
      <NavBar />
      <Home />
      {screenWidth <= 932 && (
        <div className="aboutContact">
          <About />
          <Contact className="contactMobile" />

          <ProjectsGallery projects={projectData} />
          <Skills />
        </div>
      )}
      {screenWidth > 932 && (
        <>
          <About />
          <ProjectsGallery projects={projectData} />
          <Skills />
          <Contact className="contactDesktop" />
        </>
      )}
      <Footer />
    </div>
  );
}

export default App;

import React, { useEffect } from "react";
import Resume from "../../../src/WilliamHoare-Resume2024.pdf";
import "./Home.scss";
import profilephoto from "../../assets/Images/profile4.png";

function Home() {
  useEffect(() => {
    // let lastPosition = -50; // Initial position
    // let ticking = false;
    // const bannerProfilePhoto = document.querySelector(".banner-profilephoto");
    // const handleScroll = () => {
    //   const currentPosition = window.scrollY;
    //   if (!ticking) {
    //     window.requestAnimationFrame(() => {
    //       let initialBottom = window.innerWidth >= 768 ? -250 : -50;
    //       let newBottom = Math.min(initialBottom + currentPosition, 0);
    //       if (newBottom !== lastPosition) {
    //         bannerProfilePhoto.style.bottom = `${newBottom}px`;
    //         lastPosition = newBottom;
    //       }
    //       ticking = false;
    //     });
    //     ticking = true;
    //   }
    // };
    // Conditionally add the scroll event listener based on screen width
    // if (window.innerWidth < 932) {
    //   window.addEventListener("scroll", handleScroll);
    // }
    // return () => {
    //   // Remove the scroll event listener when the component unmounts
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, []);

  // Determine which image to display based on screen width
  const imageSource = profilephoto;
  // window.innerWidth >= 932 ? profilephoto : profilephotofull;

  return (
    <div className="home">
      <div className="banner-profilephoto">
        <img src={imageSource} alt="profilephoto" />
      </div>
      <div className="profile_container">
        {/* Your JSX content here */}
        <h1
          className="profile-header"
          style={{ fontFamily: "'Druk Wide Bold', sans-serif" }}
        >
          Will Hoare
        </h1>
        <h2 className="profile-subheader">Full-Stack Developer</h2>

        <div className="profile-button__container">
          <a
            href="https://www.linkedin.com/in/william-hoare/"
            target="_blank"
            rel="noreferrer"
            className="profile-button"
            role="button"
          >
            <button className="learn-more">LinkedIn</button>
          </a>
          <a
            href="https://github.com/willhoare"
            target="_blank"
            rel="noreferrer"
            className="profile-button"
            role="button"
          >
            <button className="learn-more">GitHub</button>
          </a>
          <a
            href={Resume}
            target="_blank"
            rel="noreferrer"
            className="profile-button"
            role="button"
          >
            <button className="learn-more">Resume</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;

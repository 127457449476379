import "./Contact.scss";
import React, { useRef } from "react";
import emailjs from "emailjs-com";
import { Form, Input, Button } from "semantic-ui-react";
import Swal from "sweetalert2";
import Li from "../../assets/Icons/Logo/li.svg";
import Calendly from "../../assets/Icons/Logo/calendly.svg";
import Gmail from "../../assets/Icons/Logo/gmail.svg";

const SERVICE_ID = "service_nx3ears";
const TEMPLATE_ID = "template_zic0bn4";
const USER_ID = "OIDomCLqL_JyUPkO3";

const ForwardedTextArea = React.forwardRef((props, ref) => (
  <Form.TextArea {...props} ref={ref} />
));

const Contact = () => {
  const messageRef = useRef(null);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully",
        });
      },
      (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: error.text,
        });
      }
    );
    e.target.reset();
  };

  return (
    <div className="contact">
      <h2 className="contact-header">Connect with me</h2>
      <div className="contact-networks">
        <div className="contact-networks__wrapper">
          <a
            href="https://www.linkedin.com/in/william-hoare/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="contact-linkedin" src={Li} alt="Li" />
          </a>
        </div>
        <div className="contact-networks__wrapper">
          <a href="mailto:williamhoare@gmail.com">
            <img className="contact-gmail" src={Gmail} alt="GitHub" />
          </a>
        </div>
        <div className="contact-networks__wrapper">
          <a
            href="https://calendly.com/williamhoare"
            target="_blank"
            rel="noreferrer"
          >
            <img className="contact-calendly" src={Calendly} alt="Calendly" />
          </a>
        </div>
      </div>
      <div className="contact-networks__text">
        <p className="contact-title">Linkedin</p>
        <p className="contact-title">Gmail</p>
        <p className="contact-title">Calendly</p>
      </div>
      <Form onSubmit={handleOnSubmit}>
        <Form.Field
          id="form-input-control-email"
          control={Input}
          name="user_email"
          placeholder="Email"
          required
          icon="mail"
          iconPosition="left"
        />
        <Form.Field
          id="form-input-control-last-name"
          control={Input}
          name="user_name"
          placeholder="Name"
          required
          icon="user circle"
          iconPosition="left"
        />
        <ForwardedTextArea
          id="form-textarea-control-opinion"
          name="user_message"
          placeholder="Message"
          required
          ref={messageRef}
        />
        <Button type="submit" color="green" className="learn-more">
          <span className="profile-button__text">Submit</span>
        </Button>
      </Form>
    </div>
  );
};

export default Contact;

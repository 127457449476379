import React from "react";
import { Link } from "react-scroll";
import "./NavBar.scss";

function NavBar() {
  return (
    <header>
      <nav className="navbar">
        <ul className="navbar-list">
          <li className="navbar-list__item">
            <Link activeClass="active" smooth spy to="home">
              HOME
            </Link>
          </li>
          <li className="navbar-list__item">
            <Link activeClass="active" smooth spy to="about" offset={-100}>
              ABOUT
            </Link>
          </li>
          <li className="navbar-list__item">
            <Link activeClass="active" smooth spy to="projects" offset={-100}>
              PROJECTS
            </Link>
          </li>
          <li className="navbar-list__item">
            <Link activeClass="active" smooth spy to="skills" offset={-100}>
              SKILLS
            </Link>
          </li>

          <li className="navbar-list__item">
            <Link activeClass="active" smooth spy to="contact" offset={-100}>
              CONTACT
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default NavBar;

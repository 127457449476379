import "./About.scss";
import React, { useEffect } from "react";

// import Profile from "../../assets/Images/mainpic.png";

function About() {
  useEffect(() => {
    const projectSections = document.querySelectorAll(
      ".contact-picture__container, .contact-flex"
    );

    const animateSectionsOnScroll = () => {
      projectSections.forEach((section) => {
        const sectionTop = section.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (sectionTop < windowHeight * 0.8) {
          section.classList.add("animate");
        } else {
          section.classList.remove("animate");
        }
      });
    };

    window.addEventListener("scroll", animateSectionsOnScroll);
    animateSectionsOnScroll(); // Trigger initial animation on component mount

    return () => {
      window.removeEventListener("scroll", animateSectionsOnScroll);
    };
  }, []);
  return (
    <div className="about">
      {/* <h2 className="contact-header">About Me</h2> */}

      <div className="about-flex">
        <div className="contact-flex">
          <h3 className="contact-nameheaderdesktop">About Me</h3>
          {/* <h4 className="contact-subheaderdesktop"> </h4> */}
          {/* <h3 className="contact-copy__header">Hello 🌎</h3> */}
          <h3 className="contact-copy">Hello there! </h3>

          <p className="contact-copy">
            I'm Will, a full-stack developer with a background in marketing and
            account management. I specialize in creating user-friendly,
            responsive websites and have a track record of working directly with
            clients to meet their needs effectively.
          </p>
          <p className="contact-copy">
            Through my career I've had the opportunity to work with a diverse
            range of clients in the CPG, Medical, and Non-profit industries. I
            am adept at working with variety of languages, frameworks and
            technologies, ensuring that each project is executed proficiently
            and delivered on time and within budget. I believe that my marketing
            background enriches my approach to web development, providing
            valuable insights into user engagement and digital strategies.
          </p>
          <p className="contact-copy">
            A keen traveler and music lover, I emigrated to Canada from the UK
            in 2018.
          </p>

          <p className="contact-copy">
            Check out some examples of my recent work below and feel free to
            drop me a line if you want to connect on any projects.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;

import React, { useState } from "react";
import "./ProjectsGallery.scss";

const ProjectsComponent = ({ projects }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState({
    id: null,
    img: "",
  });

  const openModal = (id, img) => {
    setModalData({ id, img });
    setModalVisible(true);
  };

  const closeModal = () => {
    // Add class to scale down before hiding the modal
    const modalContent = document.querySelector(".modal-content");
    if (modalContent) {
      modalContent.classList.add("hide");
    }

    setTimeout(() => {
      setModalVisible(false);
      if (modalContent) {
        modalContent.classList.remove("hide");
      }
    }, 300); // 300ms matches the duration of the scaleDown animation
  };

  const handleLiveClick = (link) => {
    window.open(link, "_blank");
  };

  const handleGithubClick = (github) => {
    window.open(github, "_blank");
  };

  return (
    <section className="projects">
      <h1 className="contact-nameheaderdesktop">Projects</h1>

      <div className="container">
        {projects.map((project) => (
          <div className="item" id={project.id} key={project.id}>
            <img src={project.img} alt="" />
            <div className="text">
              <h3>{project.title}</h3>
              {/* <p>{project.info}</p> */}
            </div>
            <div
              className="button"
              onClick={() => openModal(project.id, project.img)}
            >
              Learn More
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {modalVisible && (
        <div id="overlay" className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img id="img" src={modalData.img} alt="" />
            <div id="details">
              <h3 id="title">{projects[modalData.id - 1].title}</h3>
              <p id="info">{projects[modalData.id - 1].info}</p>
              <p id="cms">
                <b>CMS: </b>
                {projects[modalData.id - 1].cms}
              </p>
              <p id="techStack">
                <b>Skills: </b>
                {projects[modalData.id - 1].techStack.join(", ")}
              </p>
              <div
                className="button"
                id="live"
                onClick={() => handleLiveClick(projects[modalData.id - 1].link)}
              >
                Visit site
              </div>
              <i
                className="fab fa-github-square"
                id="github"
                onClick={() =>
                  handleGithubClick(projects[modalData.id - 1].github)
                }
              ></i>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ProjectsComponent;

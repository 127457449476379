import React from "react";
import "./Footer.scss";
import linkedinfooter from "../../assets/Icons/linkedin.png";
import githubfooter from "../../assets/Icons/github.png";

function Footer() {
  return (
    <div className="footer">
      {/* <p className="footer-text">Thank you for visiting!</p> */}
      <div className="footer-text">
        <a
          href="https://www.linkedin.com/in/william-hoare/"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <img
            src={linkedinfooter} // Adjust the path as needed
            alt="LinkedIn"
          />
        </a>
        <a
          href="https://github.com/willhoare"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <img
            src={githubfooter} // Adjust the path as needed
            alt="GitHub"
          />
        </a>
      </div>
      <p className="footer-text">William Hoare, 2024</p>
    </div>
  );
}

export default Footer;
